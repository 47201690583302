.documents{
    color: #284d8c;
}

.documents:hover{
    background-color: #598be2;
    font-weight: 600;
    color: aliceblue !important;
    border-radius: 3px;
    
}
.active-side{
    background-color: #598be2;
    font-weight: 600;
    border-radius: 3px;
    color: white !important;
}

