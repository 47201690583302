
  
.image-name img{
    width: 25px;
    margin-right: 12px;
}
.search-users{
    margin-top: -28.7px;
    padding-bottom: 1rem;    
}
.dropdown .dropdown-toggle{
    font-size: 0.85rem;
}
.dropdown .dropdown-menu{
    font-size: 0.85rem;
}
.col .delete {
    font-size: 0.85rem;
}
.col .bulk-change{
    font-size: 0.85rem;
    white-space: nowrap;
}
/* .container .button-contact{
    margin-left: 36.2rem;
} */
.offcanvas-content{
    overflow: auto;
}
@media(max-width:767px){
    .container .button-contact{
        margin-left: -1rem;
    }
    .dropdown .dropdown-toggle{
        font-size: 0.78rem;
    }
    .dropdown .dropdown-menu{
        font-size: 0.75rem;
    }
    .col .bulk-change{
        font-size: 0.78rem;
        white-space: nowrap;
    }
    .col .delete {
        font-size: 0.78rem;
    }
}



  