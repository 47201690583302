#body {
    font-size: 0.8rem !important;
}
.label{
    color: rgb(21, 56, 57);
    font-weight: 700;
}

.profile-card img{
    width: 150px;
}
@media(max-width:767px) {
    .row img{
        margin-right:100px;
    }
    .profile-card .img-icon{
        margin-right: 3px;
    }
    ul li{
        font-size: 12px;
    }
}