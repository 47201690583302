#floatingButtonFQP {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 1;
}

#floatingButtonLPP {
  position: fixed;
  bottom: 65px;
  right: 20px;
  padding: 10px 20px;
  z-index: 1;
  cursor: pointer;
  border-radius: 10px;
  transition: opacity 0.3s ease;
}

#floatingButtonFDC {
  position: fixed;
  bottom: 110px;
  right: 20px;
  padding: 10px 20px;
  z-index: 1;
  cursor: pointer;
  border-radius: 10px;
  transition: opacity 0.3s ease;
}
