div .search{
    width: 12rem;
    right: 0;
    margin-right: 1.3rem;
}
div .search:hover{
    border: 1px solid #6b6bff;
}

.action-icon{
    margin-right: 2rem;
}
div .icon-button{
    border: none;
    height: 12px;
    width: 25px;
    background: none;
}
div button .danger:hover{
    font-size: 15px;
    color: rgb(238, 80, 80);
}
div button .edit:hover{
    font-size: 15px;
    color: rgb(64, 99, 255);
}
div button .show:hover{
    font-size: 15px;
    color:#6b6bff;
}

.image-name img{
    width: 40px;
    margin-right: 12px;
}

@media(max-width:767px){
    div .search-users{
        margin-top: -26px;
    }
}