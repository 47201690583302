.title{
    font-size: 12px;
}
.toggle-sidebar-btn {
    font-size: 50px;
    margin-left: -10px;
  }
  
  @media (max-width: 767px) {
    .toggle-sidebar-btn {
      font-size: 50px;
      margin-left: 10px;
    }
  }